
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
  data() {
    return {};
  },
  components: {},
});
