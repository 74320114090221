
import { defineComponent } from "vue";
import Menu from "@/components/Menu.vue";

export default defineComponent({
  name: "App",
  components: {
    Menu,
  },
});
