
import { defineComponent } from "vue";

export default defineComponent({
  name: "Menu",
  methods: {
    scrollToElement(e: string) {
      const target = document.querySelector(`#${e}`);
      if (target != null) {
        target.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "end",
        });
      }
    },
  },
});
